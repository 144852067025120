// 机构信用评价与监督
<template>
  <div class="org_credit">
    <div class="panel page_width">
      <div class="panel_header tw-flex">
        <img src="../../assets/images/home_icon.png" alt="" style="margin-right:16px;" />
        <p class="text_size_16 tw-font-bold">信用评价与监管</p>
      </div>
      <div class="panel_condition">
        <condition-list
          label="产业特长"
          v-model="condition.industryExpertise"
          :list="technicalList"
          item-label="name"
          item-value="id" />
      </div>
      <p class="panel_result text_size_14">
        搜索结果
        <span class="tw-font-bold">{{pagination.total}}</span>
      </p>
      <div class="panel_table">
        <el-table
          v-loading="loading"
          :data="tableData"
          :header-cell-style="headerCellStyle"
          @row-click="onDetail">
          <el-table-column
            type="index"
            label="序号"
            :index="indexMethod">
          </el-table-column>
          <el-table-column
            prop="organizationName"
            label="机构名称">
          </el-table-column>
          <el-table-column
            prop="username"
            label="机构管理员">
          </el-table-column>
          <el-table-column
            prop="avgScore"
            label="综合信用分">
          </el-table-column>
          <el-table-column
            prop="platformScore"
            label="平台打分">
          </el-table-column>
          <el-table-column
            prop="serviceQuality"
            label="服务质量">
          </el-table-column>
          <el-table-column
            prop="infoScore"
            label="信息完善">
          </el-table-column>
          <el-table-column
            prop="serviceEffect"
            label="服务效果">
          </el-table-column>
          <el-table-column
            prop="serviceAttitude"
            label="服务态度">
          </el-table-column>
        </el-table>
      </div>
      <div class="panel_pagination tw-flex tw-justify-center tw-items-center">
        <el-pagination
          hide-on-single-page
          background
          :current-page.sync="pagination.pageNum"
          :page-size="pagination.pageSize"
          layout="prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import conditionList from './condition-list'

export default {
  components: {
    conditionList
  },
  data () {
    return {
      loading: false,
      condition: {
        industryExpertise: ''
      },
      tableData: [],
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      } // 分页对象
    }
  },
  computed: {
    headerCellStyle () {
      return this.$store.state.headerCellStyle
    },
    // 技术领域
    technicalList () {
      return this.$store.state.technicalList
    }
  },
  watch: {
    condition: {
      immediate: true,
      deep: true,
      handler: function (val, oldVal) {
        this.search(this.condition)
      }
    },
    pagination: {
      immediate: true,
      deep: true,
      handler: function (val, oldVal) {
        this.search(this.condition)
      }
    }
  },
  methods: {
    indexMethod (index) {
      return this.pagination.pageSize * (this.pagination.pageNum - 1) + index + 1
    },
    search (condition) {
      this.loading = true
      api.searchOrg({
        pageNum: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
        ...condition
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    onDetail (row, column, event) {
      const { href } = this.$router.resolve({
        path: `/org-credit/detail/${row.id}`
      })
      window.open(href, '_blank')
    }
  }

}
</script>

<style lang="scss" scoped>
.org_credit {
  padding: 30px 0 20px 0;
}
.panel {
  padding: 0 40px 20px 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  &_condition {
    width: 1130px;
    padding: 0 20px;
    background: #ffffff;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
  }
  &_result {
    margin: 16px 0;
    line-height: 20px;
    color: #909399;
    & > span {
      display: inline-block;
      color: #3473E6;
      margin-left: 10px;
    }
  }
  &_header {
    padding: 30px 0 20px 0;
    line-height: 22px;
  }
  &_pagination {
    margin-top: 20px;
    height: 40px;
  }
}
</style>
