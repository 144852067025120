<template>
  <div
    class="condition tw-flex"
    :style="{
      height: isOpen ? 'auto' : '40px'
    }">
    <p class="condition_label text_size_14">{{label}}：</p>
    <div class="condition_list tw-flex-1 tw-flex tw-items-center tw-flex-wrap">
      <p
        class="condition_list_item"
        :class="{ selected: value === '' }"
        @click="onSelect({ [itemValue]: '' })">
        不限
      </p>
      <p
        class="condition_list_item"
        :class="{ selected: item[itemValue] === value }"
        v-for="item in list"
        :key="item[itemValue]"
        @click="onSelect(item)">
        {{item[itemLabel]}}
      </p>
    </div>
    <div class="condition_switch tw-flex tw-items-center" @click="toggleOpen">
      <p class="text_size_14">{{isOpen ? '收起' : '更多'}}</p>
      <i class="el-icon-arrow-up" v-show="isOpen" />
      <i class="el-icon-arrow-down" v-show="!isOpen" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    itemLabel: {
      type: String,
      default: 'label'
    },
    itemValue: {
      type: String,
      default: 'value'
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    onSelect (item) {
      const value = this.value
      if (value !== item[this.itemValue]) {
        this.$emit('change', item[this.itemValue], value)
      }
      this.$emit('input', item[this.itemValue])
    },
    toggleOpen () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.condition {
  overflow: hidden;
  margin-bottom: 10px;
  &_label {
    width: 120px;
    line-height: 30px;
    padding-left: 10px;
    margin-top: 10px;
    color: #909399;
  }
  &_list {
    margin-right: 74px;
    &_item {
      cursor: pointer;
      line-height: 20px;
      padding: 5px 10px;
      margin-right: 10px;
      margin-top: 10px;
      border-radius: 2px;
      color: #303133;
      &:hover, &.selected {
        color: #FFFFFF;
        background-color: #3473E6;
      }
    }
  }
  &_switch {
    cursor: pointer;
    width: 48px;
    height: 30px;
    margin-top: 10px;
    color: #3473E6;
    & > i {
      margin-left: 4px;
    }
  }
}
</style>
